body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: antiquewhite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-sevasoft {
  background-color: #2d4f6b;
  /*background-color: #B3DEE5;*/

}
.navbar-sevasoft a {
  color: antiquewhite;
}
.navbar-sevasoft a:visited  {
  color: antiquewhite;
}
.navbar-sevasoft-toggler-icon {
  color: antiquewhite;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")
}

.App {
  text-align: center;
  background: #2D4F6B;
  background: linear-gradient(to bottom, #B3DEE5 10%, #2D4F6B 150%);
  /*background: linear-gradient(to bottom, #2D4F6B 0%, #B3DEE5 1%, #2D4F6B 80%);*/
  min-height: 100vh;
  display: block;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App section {
  display: block;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: none;
}
.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.App-section-slogan {
  /*padding: 0.1rem;*/
  /*display: flex;*/
  display: inline-block;
}

.App-section-slogan .slogan-blok {
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  text-align: justify;
  color: #2D4F6B;
  /*color: #FAE6B1;*/
  /*color: #FFA101;*/

  /*border-color: #2D4F6B;*/

  border: 1px;
  border-radius: 1rem;
  border-style: solid;
  border-color: #FFA101;

  align-items: center;
  font-size: calc(10px + 1.5vmin);
}




.footer{
    position: absolute;
    background: #2D4F6B;
    /*margin-left: 3rem;*/
    /*margin-right: 3rem;*/

    /*background: -webkit-linear-gradient(to bottom, #2D4F6B 0%, #B3DEE5 1%, #2D4F6B 100%);*/
    /*background: -moz-linear-gradient(to bottom, #2D4F6B 0%, #B3DEE5 1%, #2D4F6B 100%);*/
    /*background: linear-gradient(to bottom, #B3DEE5 0%, #2D4F6B 100%); */
    color: antiquewhite;
    width: 100%;

    /*min-height: 15px;*/
    /*bottom: 1px;*/
    /*left: 2rem;*/
    /*right: 2rem;*/

    /*position: relative;*/
    /*margin-top: -150px; !* negative value of footer height *!*/
    /*height: 10px;*/
    clear:both;
    /*padding-top:20px;*/
}

.footer-brand {
    margin-left: 3rem;
    margin-right: 3rem;

    /*left: 2rem;*/
    /*right: 2rem;*/


}

.footer a {
    color: antiquewhite;
    text-decoration: none;
    cursor: pointer;
}

