.footer{
    position: absolute;
    background: #2D4F6B;
    /*margin-left: 3rem;*/
    /*margin-right: 3rem;*/

    /*background: -webkit-linear-gradient(to bottom, #2D4F6B 0%, #B3DEE5 1%, #2D4F6B 100%);*/
    /*background: -moz-linear-gradient(to bottom, #2D4F6B 0%, #B3DEE5 1%, #2D4F6B 100%);*/
    /*background: linear-gradient(to bottom, #B3DEE5 0%, #2D4F6B 100%); */
    color: antiquewhite;
    width: 100%;

    /*min-height: 15px;*/
    /*bottom: 1px;*/
    /*left: 2rem;*/
    /*right: 2rem;*/

    /*position: relative;*/
    /*margin-top: -150px; !* negative value of footer height *!*/
    /*height: 10px;*/
    clear:both;
    /*padding-top:20px;*/
}

.footer-brand {
    margin-left: 3rem;
    margin-right: 3rem;

    /*left: 2rem;*/
    /*right: 2rem;*/


}

.footer a {
    color: antiquewhite;
    text-decoration: none;
    cursor: pointer;
}
